<template>
  <div>
    <NuxtLayout name="default">
      <div class="page-content">
        <MipixCard class="page-box-content">
          <slot />
        </MipixCard>
        <div v-if="rigthLinks">
          <PagesTermsRightLinks />
        </div>
      </div>
    </NuxtLayout>
  </div>
</template>

<script lang="ts" setup>
const route = useRoute();
const rigthLinks = computed<boolean>(() => route.matched.some(record => record.name !== 'promotions-promotion'));
</script>

<style scoped>
  .page-content {
    @apply w-full container mt-8 mx-auto flex flex-col-reverse md:flex-row justify-center gap-4;
  }

  .page-content .page-box-content {
    @apply w-full h-auto min-h-[10rem] p-4 xl:p-6 rounded-lg;
  }
  .page-content .page-box-content .top-bar {
    @apply relative pb-4;
  }
</style>