<template>
  <MipixCard class="page-box-content box-menu-content">
    <nuxt-link :to="{ name: 'betting-terms' }" :class="{'active': $route.name == 'betting-terms'}">TERMOS DE APOSTAS</nuxt-link>
    <nuxt-link :to="{ name: 'terms-and-conditions' }" :class="{'active': $route.name == 'terms-and-conditions'}">TERMOS E CONDIÇÕES</nuxt-link>
    <nuxt-link :to="{ name: 'kyc-policy' }" :class="{'active': $route.name == 'kyc-policy'}">POLÍTICA KYC</nuxt-link>
    <nuxt-link :to="{ name: 'privacy' }" :class="{'active': $route.name == 'privacy'}">POLÍTICA DE PRIVACIDADE</nuxt-link>
    <nuxt-link :to="{ name: 'responsible-gaming' }" :class="{'active': $route.name == 'responsible-gaming'}">JOGO RESPONSÁVEL</nuxt-link>
    <nuxt-link :to="{ name: 'about' }" :class="{'active': $route.name == 'about'}">SOBRE A MIPIX</nuxt-link>
  </MipixCard>
</template>

<style>
  .box-menu-content {
    @apply w-full xl:!w-60 flex flex-col p-8 gap-3;
  }
  .box-menu-content a {
    @apply text-white/70 hover:text-white;
  }
  .box-menu-content a.active {
    @apply text-white;
  }
</style>